import React from 'react';
import { Container, Grid, Typography, Card } from '@mui/material';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, BarChart, Bar, PieChart, Pie, Cell, Legend } from 'recharts';
import { styled } from '@mui/system';

const CaseStudyContainer = styled('div')({
  backgroundColor: '#1e1e1e',
  padding: '80px 20px',
  color: '#fff',
  textAlign: 'center',
});

const ChartCard = styled(Card)({
  backgroundColor: '#292929',
  color: '#fff',
  borderRadius: '15px',
  boxShadow: '0 8px 30px rgba(0, 255, 0, 0.15)',
  padding: '20px',
});

const caseStudyData = [
  { month: 'Jan', fraudAttempts: 200, prevented: 180 },
  { month: 'Feb', fraudAttempts: 250, prevented: 240 },
  { month: 'Mar', fraudAttempts: 220, prevented: 210 },
  { month: 'Apr', fraudAttempts: 300, prevented: 290 },
  { month: 'May', fraudAttempts: 320, prevented: 310 },
];

const chargebackData = [
  { month: 'Jan', chargebacks: 50, prevented: 45 },
  { month: 'Feb', chargebacks: 60, prevented: 55 },
  { month: 'Mar', chargebacks: 45, prevented: 43 },
  { month: 'Apr', chargebacks: 70, prevented: 65 },
  { month: 'May', chargebacks: 75, prevented: 70 },
];

const fraudTypeData = [
  { type: 'Credit Card Fraud', occurrences: 100 },
  { type: 'ACH Fraud', occurrences: 50 },
  { type: 'Friendly Fraud', occurrences: 30 },
  { type: 'Chargeback Fraud', occurrences: 40 },
];

const chargebackRecoveryData = [
  { type: 'Recovered', value: 85 },
  { type: 'Not Recovered', value: 15 },
];

const fraudByCategoryData = [
  { category: 'E-commerce', fraudAttempts: 150, prevented: 140 },
  { category: 'Subscription Services', fraudAttempts: 120, prevented: 110 },
  { category: 'Marketplaces', fraudAttempts: 170, prevented: 160 },
  { category: 'Travel', fraudAttempts: 90, prevented: 85 },
];

const COLORS = ['#0f0', '#ff7300', '#00C49F', '#FFBB28'];

const CaseStudySection = () => {
  return (
    <CaseStudyContainer>
      <Container maxWidth="lg">
        <Typography variant="h4" gutterBottom>
          Case Study: Reducing Chargebacks and Fraud with Fraud Buster API
        </Typography>
        <Typography variant="subtitle1" paragraph>
          Learn how an e-commerce business significantly reduced chargebacks and prevented fraudulent transactions using our AI-powered fraud detection system.
        </Typography>

        {/* Case Study Story */}
        <Typography variant="body1" paragraph>
          In early 2023, an online retailer dealing with high volumes of credit card and ACH fraud experienced a surge in fraudulent transactions and chargebacks. By implementing the Fraud Buster API, they quickly reduced chargeback disputes and fraudulent activity by over 90%, leading to significant savings in revenue and operational costs.
        </Typography>

        {/* Fraud Attempts vs Prevention */}
        <Grid container spacing={4}>
          <Grid item xs={12} md={6}>
            <ChartCard>
              <Typography variant="h6" gutterBottom>
                Fraud Attempts vs Fraud Prevented (2023)
              </Typography>
              <ResponsiveContainer width="100%" height={300}>
                <LineChart data={caseStudyData}>
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="month" />
                  <YAxis />
                  <Tooltip />
                  <Legend />
                  <Line type="monotone" dataKey="fraudAttempts" stroke="#ff7300" name="Fraud Attempts" />
                  <Line type="monotone" dataKey="prevented" stroke="#0f0" name="Fraud Prevented" />
                </LineChart>
              </ResponsiveContainer>
            </ChartCard>
          </Grid>

          {/* Chargebacks vs Chargebacks Prevented */}
          <Grid item xs={12} md={6}>
            <ChartCard>
              <Typography variant="h6" gutterBottom>
                Chargebacks vs Chargebacks Prevented
              </Typography>
              <ResponsiveContainer width="100%" height={300}>
                <LineChart data={chargebackData}>
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="month" />
                  <YAxis />
                  <Tooltip />
                  <Legend />
                  <Line type="monotone" dataKey="chargebacks" stroke="#ff7300" name="Chargebacks" />
                  <Line type="monotone" dataKey="prevented" stroke="#0f0" name="Chargebacks Prevented" />
                </LineChart>
              </ResponsiveContainer>
            </ChartCard>
          </Grid>

          {/* Fraud Types Breakdown */}
          <Grid item xs={12} md={6}>
            <ChartCard>
              <Typography variant="h6" gutterBottom>
                Fraud Types Breakdown
              </Typography>
              <ResponsiveContainer width="100%" height={300}>
                <BarChart data={fraudTypeData}>
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="type" />
                  <YAxis />
                  <Tooltip />
                  <Legend />
                  <Bar dataKey="occurrences" fill="#8884d8" name="Occurrences" />
                </BarChart>
              </ResponsiveContainer>
            </ChartCard>
          </Grid>

          {/* Chargeback Recovery */}
          <Grid item xs={12} md={6}>
            <ChartCard>
              <Typography variant="h6" gutterBottom>
                Chargeback Recovery Rate
              </Typography>
              <ResponsiveContainer width="100%" height={300}>
                <PieChart>
                  <Pie
                    data={chargebackRecoveryData}
                    dataKey="value"
                    nameKey="type"
                    cx="50%"
                    cy="50%"
                    outerRadius={100}
                    fill="#0f0"
                    label
                  >
                    {chargebackRecoveryData.map((entry, index) => (
                      <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                    ))}
                  </Pie>
                  <Legend />
                  <Tooltip />
                </PieChart>
              </ResponsiveContainer>
            </ChartCard>
          </Grid>

          {/* Fraud Prevention by Category */}
          <Grid item xs={12} md={12}>
            <ChartCard>
              <Typography variant="h6" gutterBottom>
                Fraud Attempts vs Fraud Prevention by Category
              </Typography>
              <ResponsiveContainer width="100%" height={400}>
                <BarChart data={fraudByCategoryData}>
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="category" />
                  <YAxis />
                  <Tooltip />
                  <Legend />
                  <Bar dataKey="fraudAttempts" fill="#ff7300" name="Fraud Attempts" />
                  <Bar dataKey="prevented" fill="#0f0" name="Fraud Prevented" />
                </BarChart>
              </ResponsiveContainer>
            </ChartCard>
          </Grid>
        </Grid>

        {/* Impact Summary */}
        <Typography variant="h5" gutterBottom style={{ marginTop: '40px' }}>
          The Impact
        </Typography>
        <Typography variant="body1" paragraph>
          By integrating Fraud Buster API, the e-commerce retailer saw a dramatic reduction in both fraudulent activity and chargebacks. Over the course of five months, they prevented over 90% of fraudulent attempts and reduced chargebacks by 85%, leading to more than $750,000 in recovered revenue and decreased operational costs. The recovery rate for chargebacks was 85%, significantly reducing operational stress and allowing the company to allocate resources toward growth and innovation.
        </Typography>
      </Container>
    </CaseStudyContainer>
  );
};

export default CaseStudySection;
